/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "bdecdaf3f60644d3b26e281855a27ddd",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ConvertAPI",
            "endpoint": "https://45je3utlqk.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "LandingLinkAPI",
            "endpoint": "https://cu6z8m7apg.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "SMSAPI",
            "endpoint": "https://zu0kh4a67i.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://z5xt7sujvbbtzj2twc5bhnacym.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:de75bda0-51df-49cf-9a4f-3dd10da4ec9a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Sqn2FuYGZ",
    "aws_user_pools_web_client_id": "3d2ii92elk0r1h0aa1p90bnd5g",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "diialoggstatic100939-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
