import React, {useCallback, useEffect, useState} from "react";
import {EmployeeAndVideoDetails} from "../../../shared/types/EmployeeAndVideoDetails";
import {Grid} from "@material-ui/core";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {ErrorBoundary} from "../../components/error-boundary/ErrorBoundary";
import {footerAreaStyles} from "./FooterArea.styles";

const FooterArea = (props: {
    employeeAndVideo: EmployeeAndVideoDetails | null
}) => {
    const classes = footerAreaStyles();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [map, setMap] = useState<GoogleMap | null>(null)
    const [center, setCenter] = useState(undefined)

    useEffect(() => {
        if (props.employeeAndVideo != null) {
            const shop = props.employeeAndVideo.video.user.shop;

            const center = {
                lat: parseFloat(shop.googleMaps ? shop.googleMaps.center.latitude : shop.latitude),
                lng: parseFloat(shop.googleMaps ? shop.googleMaps.center.longitude : shop.longitude)
            }

            // @ts-ignore
            setCenter(center)
        }
    }, [props.employeeAndVideo])

    const onLoad = useCallback(map => {
        setMap(map)
    }, [])

    const onUnmount = useCallback(map => {
        setMap(null)
    }, [])

    if (center === undefined) {
        return null
    } else {
        return (
            <ErrorBoundary>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="stretch"
                      className={classes.container}>
                    <Grid item xs className={classes.mapsContainer}>
                        {
                            (() => {
                                if (props?.employeeAndVideo?.video.user.email.includes('@nybolig.dk')) {
                                    return (
                                        <a href='https://www.nykredit.dk/dit-liv/bolig/boligberegnere/beregn-lan-til-ny-bolig/'>
                                            <img src={"../banners/nykredit_banner.png"} alt="nykredit banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@nykredit.dk')) {
                                    return (
                                        <a href='https://www.nykredit.dk/private/'>
                                            <img src={"../banners/nykredit_banner.png"} alt="nykredit banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@bibliotheca.com')) {
                                    return (
                                        <a href='https://www.bibliotheca.com/da/'>
                                            <img src={"../banners/bibliotheca_banner.png"} alt="bibliotheca banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@home.dk')) {
                                    return (
                                        <a href='https://www.home.dk'>
                                            <img src={"../banners/home_banner.jpg"} alt="home banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@dfd.dk')) {
                                    return (
                                        <a href='https://dfd.dk/loesninger/dfd-upcy/'>
                                            <img src={"../banners/dfd_banner.jpeg"} alt="dfd banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@able.dk')) {
                                    return (
                                        <a href='https://able.dk/snacks/'>
                                            <img src={"../banners/able_banner.jpeg"} alt="able banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@gjensidige.dk')) {
                                    return (
                                        <a href='https://www.gjensidige.dk/erhverv/forebyg-skader/'>
                                            <img src={"../banners/gjensidige_banner.png"} alt="gjensidige banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@bolou.dk')) {
                                    return (
                                        <a href='https://bolou.dk/'>
                                            <img src={"../banners/bolou_banner.jpg"} alt="bolou banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@poulstigbriller.dk')) {
                                    return (
                                        <a href='https://appointments.optikit.dk/da/ZDc4YWQwZTYtYTIwNy00YTczLTkwNDYtOTI2NjVlN2Q3ZmI1?store=2299'>
                                            <img src={"../banners/psbfooter.jpg"} alt="ps banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@nyhavn.dk')) {
                                    return (
                                        <a href='https://nyhavn.dk/'>
                                            <img src={"../banners/nyhavn-footer.png"} alt="nyhavn banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else if (props?.employeeAndVideo?.video.user.email.includes('@pernilleslot.com') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@lho.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@accunia.com') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@formulax.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@almotech.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@snevagten.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@stageone.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@rezultor.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@diialogg.com') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@learnto.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@service-co.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@advisedby.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@ifld.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@salgsmani.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@regnskabshus.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@skixer.com') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@bilhandel.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@nbfilm.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@jobboozt.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@maeglerakademiet.dk') ||
                                    props?.employeeAndVideo?.video.user.email.includes('@lockton.com' )) {
                                    return (
                                        <a href='https://diialogg.com/'>
                                            <img src={"../banners/Overskrift.png"} alt="Diialogg banner"
                                                 className={classes.image}/>
                                        </a>
                                    )
                                } else {
                                    return (
                                        <LoadScript googleMapsApiKey="AIzaSyCwg2d8z3LiARAdEbgp4D1HCefYVxVay6Y">
                                            <GoogleMap mapContainerStyle={{
                                                width: '100%',
                                                minHeight: 150
                                            }}
                                                       center={center}
                                                       zoom={(
                                                           props?.employeeAndVideo?.video.user.shop.googleMaps ?
                                                               props.employeeAndVideo.video.user.shop.googleMaps.zoom :
                                                               10
                                                       ) as number}
                                                       onLoad={onLoad}
                                                       onUnmount={onUnmount}
                                                       options={{disableDefaultUI: true}}
                                            >
                                                {
                                                    props?.employeeAndVideo?.video.user.shop.googleMaps ?
                                                        props?.employeeAndVideo?.video.user.shop.googleMaps.markers.map(marker => {
                                                            return <Marker key={marker.latitude} position={{
                                                                lat: parseFloat(marker.latitude as string),
                                                                lng: parseFloat(marker.longitude as string)
                                                            }}/>
                                                        })
                                                        :
                                                        <Marker position={{
                                                            lat: parseFloat(props.employeeAndVideo?.video?.user?.shop.latitude as string),
                                                            lng: parseFloat(props.employeeAndVideo?.video?.user?.shop.longitude as string)
                                                        }}/>
                                                }
                                                { /* Child components, such as markers, info windows, etc. */}
                                                <></>
                                            </GoogleMap>
                                        </LoadScript>
                                    )
                                }
                            })()
                        }
                    </Grid>
                </Grid>
            </ErrorBoundary>
        )
    }
}

export default FooterArea;